import React from 'react'
import NewsItem from '../components/carousels/NewsItem'
import useRevealEffect from '../../hooks/effects/useRevealEffect'
import defaultImage from '../../assets/images/empty-image-blog.svg'
import blogCategories from '../../config/blog.json'
import { formatPreviewNodes } from '../../app/utils/blog'

const isCategoryVisible = category => category.visible

const BlogLandingView = ({ blogroll }) => {
  const panelRef = useRevealEffect()
  return (
    <div className="blog-content" ref={panelRef}>
      {blogCategories.filter(isCategoryVisible).map(category => {
        const items = formatPreviewNodes(blogroll[category.key])
        return (
          <div className="blog-content-section" key={category.key}>
            <h2 className="blog-content-title">{category.title}</h2>
            <div className="blog-content-posts">
              {items.map(item => (
                <NewsItem
                  internal
                  key={item.summary}
                  href={item.href}
                  alt={item.alt}
                  image={item.image ? item.image : defaultImage}
                  date={item.date}
                  summary={item.summary}
                />
              ))}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default BlogLandingView
