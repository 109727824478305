import React from 'react'
import BlogLayout from '../layouts/BlogLayout'
import BlogLandingView from '../views/BlogLandingView'

const BlogLandingPage = ({ blogroll }) => {
  return (
    <BlogLayout blogroll={blogroll}>
      <BlogLandingView blogroll={blogroll} />
    </BlogLayout>
  )
}

export default BlogLandingPage
