import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import BlogLandingData from '../app/components/BlogLandingData'
import BlogLandingPage from '../ui/pages/BlogLandingPage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/en.json'

const Page = props => (
  <>
    <HelmetComponent
      title={metaTags.blogTitle}
      description={metaTags.blog}
      page="blog"
    />
    <AppWrapper {...props}>
      <BlogLandingData>
        {({ blogroll }) => <BlogLandingPage blogroll={blogroll} />}
      </BlogLandingData>
    </AppWrapper>
  </>
)

export default Page
