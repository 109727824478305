import React from 'react'
import { groupBlogrollBySection } from '../utils/blog'
import { graphql, StaticQuery } from 'gatsby'

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query BlogLandingDataQuery {
        blogroll: allMarkdownRemark(
          sort: {
            fields: [frontmatter___date, frontmatter___title]
            order: DESC
          }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                path
                date
                thumbnail
              }
            }
          }
        }
      }
    `}
    render={data => {
      const blogroll = groupBlogrollBySection(data.blogroll.edges)
      return (
        <>
          {children({
            blogroll: blogroll,
          })}
        </>
      )
    }}
  />
)
